<template>
    <div>
        <div class="flex">
            <div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

                <h1 class="text-2xl font-bold mb-5">Configurations</h1>

                <form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16" key="configurations">
                    
                    <template v-if="errorMessage">
                        <div class="rounded-full bg-red-100 p-3 flex flex-row items-center mb-10">
                            <div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3">
                                !
                            </div>
                            <div class="text-xs font-normal">{{ errorMessage }}</div>
                        </div>
                    </template>

                    <form-group
                        type="select"
                        :options="daysOfTheMonth"
                        select-display-type="grid"
                        :select-grid-columns="5"
                        left-icon="calendar-outline"
                        name="default_billing_date"
                        :loading="form.fetching"
                        :form="form"
                        v-model="form.data.default_billing_date.value"
                    >
                        Default Billing Date
                    </form-group>

                    <form-group
                        type="select"
                        :options="daysOfTheMonth"
                        select-display-type="grid"
                        :select-grid-columns="5"
                        left-icon="calendar-outline"
                        name="payment_date"
                        :loading="form.fetching"
                        :form="form"
                        v-model="form.data.payment_date.value"
                    >
                        Settlement/Payment Date
                    </form-group>

                    <div class="flex flex-row">
                        <div class="w-8/10">
                            <form-group
                                type="number"
                                left-icon="settings-outline"
                                name="pay_day_loan_limit_percentage"
                                :loading="form.fetching"
                                :form="form"
                                v-model="form.data.pay_day_loan_limit_percentage.value"
                            >
                                Pay Day Loan Limit Percentage
                            </form-group>
                        </div>
                        <div class="w-2/10">
                            <div class="text-sm text-blue-400">%</div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-8/10">
                            <form-group
                                type="number"
                                left-icon="settings-outline"
                                name="pay_day_loan_max_tenure"
                                :loading="form.fetching"
                                :form="form"
                                v-model="form.data.pay_day_loan_max_tenure.value"
                            >
                                Pay Day Loan Maximum Tenure
                            </form-group>
                        </div>
                        <div class="w-2/10">
                            <div class="text-sm text-blue-400">Months</div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-8/10">
                            <form-group
                                type="number"
                                left-icon="settings-outline"
                                name="standard_loan_limit_percentage"
                                :loading="form.fetching"
                                :form="form"
                                v-model="form.data.standard_loan_limit_percentage.value"
                            >
                                Standard Loan Limit Percentage
                            </form-group>
                        </div>
                        <div class="w-2/10">
                            <div class="text-sm text-blue-400">%</div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-8/10">
                            <form-group
                                type="number"
                                left-icon="settings-outline"
                                name="standard_loan_max_tenure"
                                :loading="form.fetching"
                                :form="form"
                                v-model="form.data.standard_loan_max_tenure.value"
                            >
                                Standard Loan Maximum Tenure
                            </form-group>
                        </div>
                        <div class="w-2/10">
                            <div class="text-sm text-blue-400">Months</div>
                        </div>
                    </div>

                    <div class="text-right">
                        <button
                            type="submit"
                            class="button bg-blue-500 hover:bg-blue-600 text-white"
                            :disabled="form.loading || form.fetching"
                        >
                            <span v-if="form.fetching">Fetching Default...</span>
                            <span v-else-if="form.loading">Saving...</span>
                            <span v-else>Save Changes</span>
                        </button>
                    </div>

                </form>

            </div>
        </div>

        <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="modal">

            <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

            <div class="text-lg font-bold mb-4">
                Success!
            </div>
            <div class="text-xs mb-10">
                Configurations Updated.
            </div>

            <router-link :to="{name: 'dashboard'}" class="button bg-blue-500 hover:bg-blue-600 text-white">
                Go to Dashboard
            </router-link>
            
        </modal>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                checkmark: require('@/assets/checkmark-base.svg'),
                configurations: [],
                form: this.$options.basicForm([
                    'pay_day_loan_limit_percentage',
                    'pay_day_loan_max_tenure',
                    'standard_loan_limit_percentage',
                    'standard_loan_max_tenure',
                    'default_billing_date',
                    'payment_date',
                ], { fetching: false }),
            }
        },
        computed: {
            daysOfTheMonth() {
                const days = [];

                for (var i = 0; i < 28; i++) {
                    const day = i+1;
                    const value = day;
                    const title = day.toPosition();
                    days.push({ title, value });
                }

                return days;
            },
            errorMessage() {
                if (this.form.error.toString().match(/Error: Network Error/i)) {
                    return 'Please check your internet connection';
                }

                const errors = this.form.error?.response?.data?.errors;
                if (errors && Object.keys(errors).length) {
                    return 'Please check the form for incorrect or missing data';
                }

                return null;
            }
        },
        beforeMount() {
            this.getConfiguration();
        },
        methods: {
            async getConfiguration() {
                this.form.fetching = true;
                await this.$get({
                    url: `${this.$baseurl}/configurations`,
                    headers: this.headers,
                    success: (response) => {
                        this.configurations = response.data.configurations;
                        this.mapConfigurations();
                    },
                    error: error => {
                        console.log(error);
                    }
                });
                this.form.fetching = false;
            },
            mapConfigurations() {
                for (let name in this.form.data) {
                    console.log(name)
                    const config = this.configurations.find(config => config.name === name);
                    this.form.data[name].value = config?.value || '';
                    console.log(this.form.data[name])
                }
            },
            async submit() {
                if (!this.validateForm(this.form)) {
                    return false;
                }

                this.form.loading = true;
                await this.$put({
                    url: `${this.$baseurl}/configurations`,
                    data: this.getFormData(),
                    headers: this.headers,
                    success: () => {
                        this.$refs.modal.open();
                    },
                    error: error => {
                        this.form.error = error;
                        this.mapFormErrors(this.form, error?.response?.data?.errors);
                    }
                });
                this.form.loading = false;
            }
        }
    }
</script>